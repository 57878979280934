import React, { useEffect, useState } from "react";
import { LoadingButton } from "../../assets/loading/LoadingButton";
import API from "util/API/api";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postAPIData } from "Hooks/Services";
import { toast } from "react-hot-toast";
const OtpValidator = yup.object().shape({
  otp: yup
    .string()
    .min(4, "Enter a valid OTP")
    .max(4, "Enter a valid OTP")
    .required("OTP is required"),
});
const VerifyOtp = ({
  mobileNumber,
  loading,
  setLoading,
  currentPage,
  setCurrentPage,
  onClick,
  otpCode,
}) => {
  const [time, setTime] = useState(59);
  let timer;
  const [currOTP, setCurrOTP] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { otp: "" },
    resolver: yupResolver(OtpValidator),
  });
  const verifyOTP = async (data) => {
    try {
      setLoading(true);
      const verifyResp = await postAPIData({
        ...API.ForgotPasswordMatchOTP(data),
      });
      if (verifyResp?.data?.IsSuccessful) {
        toast.success(verifyResp?.data?.FriendlyMessage);
        setCurrentPage("update-password");
      } else {
        toast.error(
          verifyResp?.data?.FriendlyMessage || "Something went wrong"
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getFormData = (data) => {
    setTime(0);
    clearTimeout(timer);
    let obj = {
      userid: mobileNumber,
      OTP: data?.otp,
      OTPCode: otpCode?.OTPCode,
    };
    verifyOTP(obj);
  };
  useEffect(() => {
    setTime(59);
  }, [otpCode?.OTPCode]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setTimeout(() => {
      setTime((t) => t - 1);
    }, 1000);
    if (time === 0) clearTimeout(timer);
  }, [time]);
  return (
    <React.Fragment>
      <div style={{ textAlign: "center", fontSize: "18px", color: "blue" }}>
        Enter One Time Password (OTP) sent to your registered mobile No.
      </div>
      <div className="ps-5 pe-5 pb-5">
        <div className="form-group mt-2">
          <input
            {...register("otp", {
              onChange: (e) => {
                if (e?.target?.value?.length > 4) {
                  setValue("otp", currOTP);
                  return;
                }
                setCurrOTP(e?.target?.value);
              },
            })}
            type="number"
            maxLength="4"
            name="otp"
            pattern="\d{4}"
            className="form-control p-3 m-2"
            aria-describedby="mobileHelp"
            placeholder="Enter your otp"
          />
          {errors?.["otp"] && (
            <p className="errorText">{errors?.["otp"]?.message?.toString()}</p>
          )}
        </div>
        <div style={{ textAlign: "center", display: "flex", gap: "10px" }}>
          <LoadingButton
            onClick={handleSubmit(getFormData)}
            loading={loading}
            name="Confirm"
          />
          <LoadingButton
            onClick={onClick}
            disabled={time > 0 ? true : false}
            color={"gray"}
            textColor={time > 0 ? "white" : "blue"}
            loading={loading}
            name={`${time > 0 ? `Wait(0:${time})` : "Resend"}`}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerifyOtp;
