import React from "react";
import mainIcon from "./icons/Group.png";
function MainIcon({ top, bottom, left, right, width, zIndex }) {
  return (
    <React.Fragment>
      <div>
        <img
          className="iconwidth"
          draggable="false"
          src={mainIcon}
          alt="hospital-icon"
          style={{
            position: "absolute",
            top: top,
            bottom: bottom,
            left: left,
            right: right,
            width: width,
            zIndex: zIndex,
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default MainIcon;
