import { cardOrientation } from "assets/util/common/root";
import { callGet } from "common/local";
import React from "react";
const ContactUs = () => {
  const userData = callGet("auth");
  // console.log(userData, "---------->");
  return (
    <div className="patient_header mt-3 p-5" style={{ ...cardOrientation }}>
      <h2 style={{ color: "#007DC6" }}>
        <b> {userData?.hospitalName || "Marengo Asia Hospital Faridabad"}</b>
      </h2>
      <p className="mt-3 mb-3">
        {userData?.FacilityInfo?.Address ||
          "Plot No. 1, Sector 16, Faridabad, Haryana 121002"}
      </p>
      <h5 style={{ color: "#1A2352" }}>
        Emergency/Helpdesk:{" "}
        <b style={{ color: "#FF6F0F" }}>
          {" "}
          {userData?.FacilityInfo?.EmergencyNumber || "18003099999"}
        </b>
      </h5>
      <h5 style={{ color: "#1A2352" }}>
        Home Sample Collection:{" "}
        <b style={{ color: "#FF6F0F" }}>
          {" "}
          {userData?.FacilityInfo?.HomeSampleCollectionNumber || "18003099999"}
        </b>
      </h5>
      <h5 style={{ color: "#1A2352" }}>
        Email Id:{" "}
        <b style={{ color: "#FF6F0F" }}>
          {" "}
          {userData?.FacilityInfo?.Email || "helpdesk@marengoasia.com"}
        </b>
      </h5>
    </div>
  );
};

export default ContactUs;
