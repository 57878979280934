import React from "react";

const SearchTable = ({ val, setValue = () => {} }) => {
  return (
    <span className="search-table-container">
      <input
        type="text"
        placeholder="Search..."
        value={val}
        onChange={(e) => setValue(e.target.value)}
        className="form-control searchtableinput"
      />
    </span>
  );
};

export default SearchTable;
