const initilaState = {
  loading: false,
  data: [],
};

export const R_getdoctorprofile = (state = initilaState, action) => {
  switch (action.type) {
    case "GET_DOC_PRO_CALL":
      return { ...state, loading: true };

    case "GET_DOC_PRO_STOP":
      return { ...state, loading: false };

    case "GET_DOC_PRO":
      return { ...state, data: action.payload };

    default:
      return state;
  }
};
