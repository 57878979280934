var actions = {
  getPatientLogin: (payload) => ({
    type: "GET_PATIENT_LOGIN",
    payload: payload,
  }),
  A_getallspecialties: (payload) => ({
    type: "GET_ALL_SPECIALITIES",
    payload: payload,
  }),
  A_savePatientAppoint: (payload) => ({
    type: "SAVE_PATIENT_APPOINT",
    payload: payload,
  }),
  A_getPaymentData: (payload) => ({
    type: "Payment_Details",
    payload,
  }),
  A_getAllCoupons: (payload) => ({
    type: "GET_ALL_COUPONS",
    payload,
  }),
  A_searchText: (payload) => ({
    type: "Search_Text",
    payload,
  }),
  A_appointmentDetails: (payload) => ({
    type: "UPDATE_DATA",
    payload,
  }),
};

export default actions;
