import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
import { callGet } from "common/local";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "util/API/api";
const Notifications = ({ badgeCount, setBadgeCount = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const getNotifications = async (data, bool) => {
    try {
      setLoading(bool);
      var notificationResp = await postAPIData(API.getNotifications(data));
      notificationResp = notificationResp?.data;
      if (notificationResp?.IsSuccessful) {
        const respData = notificationResp?.Data?.[0];
        setBadgeCount(respData?.BadgeCount || 0);
        setNotificationData(respData);
      } else {
        toast.error(
          notificationResp?.FriendlyMessage || "Something went wrong"
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const updateBadgeCount = async (data) => {
    try {
      const authData = callGet("auth");
      data = { ...data, ...authData, FacilityId: authData?.hospitalId };
      const resp = await postAPIData(API.updateBadgeCount(data));
      if (resp?.data?.IsSuccessful) {
        setBadgeCount((e) => e - 1);
        await getNotifications(
          {
            ...authData,
            FacilityId: authData?.hospitalId,
          },
          false
        );
      } else {
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  useEffect(() => {
    const authData = callGet("auth");
    getNotifications({ ...authData, FacilityId: authData?.hospitalId }, true);
  }, []);
  return (
    <div className="container-fluid p-2 mt-2 payment-summary">
      {loading ? (
        <div className="notification-loader">
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          {notificationData?.Notifications?.length > 0 ? (
            notificationData?.Notifications?.map((e, i) => {
              return (
                <div
                  onClick={() => {
                    if (!e?.IsRead) {
                      updateBadgeCount({
                        AlertType: e?.AlertType,
                        AlertId: e?.AlertId,
                      });
                    }
                  }}
                  style={{backgroundColor: !e?.IsRead ? 'rgb(143, 186, 186, 0.5)' : 'rgb(226, 219, 219)'}}
                  className="notification-container"
                  key={i}
                >
                  <div className="notification-title">
                    {" "}
                    <h4>{e?.Title || ""}</h4>
                    <p>{new Date(e?.AlertOn).toLocaleDateString()}</p>
                  </div>
                  <p>{e?.Message || ""}</p>
                  <hr />
                </div>
              );
            })
          ) : (
            <h3 className="text-center">No Data Found</h3>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Notifications;
