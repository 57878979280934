import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  font,
  cardOrientation,
  rootButton,
} from "../../assets/util/common/root";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RootSelect from "../../assets/util/Root/RootSelect";
import { callGet } from "../../common/local";
import usePost from "../../Hooks/usePost";
import "./MyReport/MyReport/myreport.css";
import API from "../../util/API/api";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
import Table from "common/Table";
import { Icon } from "@iconify/react";

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
const schema = yup
  .object()
  .shape({
    FacilityId: yup.string().required("FacilityId is required"),
    UHID: yup.string().required("UHID is required"),
    reportType: yup.string().required("Report type is required"),
    FromDate: yup.date().nullable(),
    ToDate: yup
      .date()
      .when("FromDate", {
        is: (FromDate) => {
          return !!FromDate ? true : false;
        },
        then: yup
          .date()
          .min(
            yup.ref("FromDate"),
            "End date should be greater than start date"
          )
          .required("End date is required"),
      })
      .nullable(),
  })
  .when((values, schema) => {
    if (values?.UHID === "other") {
      return schema.shape({
        typeUHID: yup
          .string()
          .required("UHID is required")
          .min(9, "Enter a valid UHID")
          .max(9, "Enter a valid UHID"),
      });
    }
  });
const columns = [
  {
    Header: "Registration No.",
    accessor: "RegistrationNo",
  },
  {
    Header: "Service Name",
    accessor: "ServiceName",
    Cell: ({ row }) => {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          direction="row"
          spacing={0}
          className="table-service"
        >
          {row?.original?.ServiceName ||
            row?.original?.Reports?.[0]?.ServiceName ||
            "---"}
        </div>
      );
    },
  },
  {
    Header: "Invoice No.",
    accessor: "InvoiceNo",
    disableSortBy: true,
  },
  {
    Header: "Date",
    accessor: "EncodedDate",
  },
  {
    Header: "Source",
    accessor: "Source",
  },
  {
    Header: "Lab",
    accessor: "labDetails",
    className: "cell-center",
    Cell: ({ row }) => {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          {row?.original?.LabNo +
            "-" +
            (row?.original?.LabType || row?.original?.Reports?.[0]?.LabType)}
        </div>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "Status",
    accessor: "PendingStatus",
    className: "cell-center",
    Cell: ({ row }) => {
      return (
        <div
          style={{
            backgroundColor: row?.original?.Reports?.[0]?.StatusColor,
            color: row?.original?.Reports?.[0]?.StatusColor ? "white" : "black",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={0}
          className="table-status-container"
        >
          {row?.original?.PendingStatus ||
            row?.original?.Reports?.[0]?.PendingStatus ||
            "No status there"}
        </div>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "Download",
    accessor: "DownloadDetails",
    className: "cell-center",
    Cell: ({ row }) => {
      return (
        <a
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled={row?.original?.PendingStatus !== "Result Finalized"}
          direction="row"
          style={{
            pointerEvents:
              row?.original?.Reports?.[0]?.PendingStatus !==
                "Result Finalized" && "none",
          }}
          target="_blank"
          rel="noreferrer"
          alignItems="center"
          justifyContent="center"
          spacing={0}
          href={row?.original?.Reports?.[0]?.DownLoadReport || "#"}
          download={
            row?.original?.Reports?.[0]?.DownLoadReport ||
            `Report_${new Date()?.getTime()}.pdf`
          }
        >
          <button
            style={{
              backgroundColor:
                row?.original?.Reports?.[0]?.PendingStatus ===
                "Result Finalized"
                  ? "#007dc6"
                  : "#D3D3D3",
            }}
            className="table-download"
          >
            <Icon icon="lucide:download" color="white" />{" "}
          </button>
        </a>
      );
    },
  },
];

function MyReport() {
  const [loading, setLoading] = useState(false);
  const [loadingReport, getReport, setReportApi] = usePost();
  const [UHIDList, setUHIDList] = useState("");
  const [hospitalType, setHospitalType] = useState([]);
  const [result, setResult] = useState([]);
  const [otp, setOtp] = useState("");
  const [validate, setValidate] = useState(false);
  const [reportResp, setReportResp] = useState({});
  const [errorOTP, setErrorOTP] = useState(false);
  const [otpResponse, setOtpResponse] = useState({});
  const [uhidData, setUhidData] = useState("");
  const userData = callGet("auth");
  const [currPage, setCurrPage] = useState(1);
  const [inputData, setInputData] = useState({
    FacilityId: "",
    UHID: "",
    FromDate: "",
    ToDate: "",
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      reportType: "",
      FacilityId: "",
      UHID: "",
      typeUHID: "",
    },
    resolver: yupResolver(schema),
  });
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  // calling Report API
  useEffect(() => {
    if (getReport) {
      if (getReport?.IsSuccessful) {
        setReportResp(getReport);
        setResult(getReport?.Data);
      } else {
        setResult([]);
        setInputData({
          FacilityId: "",
          UHID: "",
          FromDate: "",
          ToDate: "",
        });
        toast.error(getReport?.FriendlyMessage);
      }
    }
  }, [getReport]);
  const getHospitalData = async (data) => {
    try {
      setLoading(true);
      const getUHIDByMobileResp = await postAPIData({
        ...API.getuhidlistbymobile(data),
      });
      const getData = getUHIDByMobileResp?.data;
      if (getData?.IsSuccessful) {
        setUHIDList(
          getData?.Data?.map((e) => ({
            name: `${e?.UHID} - ${e?.Name}`,
            value: e?.UHID,
          }))
        );
      } else {
        toast.error(getData?.FriendlyMessage);
      }
      const getActiveHospitalResp = await postAPIData({
        ...API.getactivehospitals(data),
      });
      const getActiveHospitals = getActiveHospitalResp?.data;
      if (getActiveHospitals?.IsSuccessful) {
        setHospitalType(
          getActiveHospitals?.Data[0]?.Hospitals.map((e) => ({
            name: e?.FacilityName,
            value: e?.FacilityId,
          }))
        );
      } else {
        toast.error(getActiveHospitals?.FriendlyMessage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("typeUHID", "");
    setOtp("");
  }, [uhidData]);
  useEffect(() => {
    setValue("FacilityId", userData?.hospitalId);
  }, [userData]);
  useEffect(() => {
    window.scroll(0, 0);
    const data = { ...callGet("auth") };
    getHospitalData(data);
  }, []);

  const mainDiv = {
    ...cardOrientation,
    minHeight: "50%",
    position: "relative",
  };
  const getReportData = (data) => {
    data.FromDate = new Date(data?.FromDate).toLocaleDateString(
      "en-US",
      options
    );
    data.ToDate = new Date(data?.ToDate).toLocaleDateString("en-US", options);
    setInputData({ ...data });
    setReportApi(API.myreports({ ...callGet("auth"), ...data }));
  };
  const matchOTP = async (data, obj) => {
    try {
      setLoading(true);
      const matchOTPResp = await postAPIData({ ...API.MatchMyReportOTP(data) });
      if (matchOTPResp?.data?.IsSuccessful) {
        toast.success(matchOTPResp?.data?.FriendlyMessage);
        obj.UHID = obj?.typeUHID;
        getReportData(obj);
        setOtp("");
        setErrorOTP(false);
      } else {
        toast.error(
          matchOTPResp?.data?.FriendlyMessage || "Something went wrong"
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getFormData = async (data) => {
    if (uhidData === "other" && (!otp || otp?.length > 4 || otp?.length < 4)) {
      setErrorOTP(true);
      return;
    }
    if (uhidData === "other") {
      const authData = callGet("auth");
      let obj = {
        ...authData,
        UHID: data?.typeUHID,
        OTP: otp,
        OTPCode: otpResponse?.OTPCode,
      };
      await matchOTP(obj, data);
    } else {
      getReportData(data);
    }
  };
  const getOTP = async (data) => {
    try {
      setLoading(true);
      const otpResp = await postAPIData({ ...API.MyReportGetOTP(data) });
      if (otpResp?.data?.IsSuccessful) {
        setOtpResponse(otpResp?.data?.Data?.[0]);
        toast.success(otpResp?.data?.FriendlyMessage);
        setValidate(true);
      } else {
        toast.error(otpResp?.data?.FriendlyMessage || "Something went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getOTPData = (data) => {
    const authData = callGet("auth");
    let obj = { ...authData, UHID: data?.typeUHID };
    getOTP(obj);
  };
  return (
    <div className="container-fluid p-0 mt-2" style={mainDiv}>
      <div className="w-100 p-5">
        <h4 className="heading fw-bold" style={font}>
          View reports
        </h4>
        <div>
          <div className="row">
            <RootSelect
              bootstrap={{
                divCss: "col-md-4 p-2",
                selectCss: "form-control p-2 rootselect",
              }}
              placeholder={"Select Report Type*"}
              options={[
                { name: "OPD", value: "OPD" },
                { name: "IPD", value: "IPD" },
                { name: "Both", value: "Both" },
              ]}
              name={"reportType"}
              register={register}
              error={errors}
            />
            <RootSelect
              bootstrap={{
                divCss: "col-md-4 p-2",
                selectCss: "form-control p-2 rootselect",
              }}
              placeholder={"Select Hospital*"}
              options={hospitalType}
              name={"FacilityId"}
              register={register}
              error={errors}
            />
            <RootSelect
              bootstrap={{
                divCss: "col-md-4 p-2",
                selectCss: "form-control p-2 rootselect",
              }}
              placeholder={"Select UHID or Name*"}
              options={[...UHIDList, { name: "OTHERS", value: "other" }]}
              name={"UHID"}
              setSelectedOption={setUhidData}
              register={register}
              error={errors}
            />
          </div>
          <div className="row">
            {uhidData === "other" && (
              <div className="col-md-4 p-2">
                <input
                  {...register("typeUHID")}
                  type="number"
                  className="form-control p-2"
                  id="number"
                  placeholder="Enter UHID*"
                  name="typeUHID"
                />
                {errors["typeUHID"] && (
                  <p className="errorText">
                    {errors["typeUHID"]?.message?.toString()}
                  </p>
                )}
              </div>
            )}
            <div className="col-md-4 p-2">
              <Controller
                name="FromDate"
                control={control}
                defaultValue={
                  new Date(new Date().setHours(0, 0, 0, 0) - 86400000 * 30)
                }
                render={({ field, ...props }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        orientation="portrait"
                        value={field.value}
                        className="report-date"
                        inputFormat="MM/DD/yyyy"
                        maxDate={
                          new Date(
                            new Date(getValues().ToDate).setHours(0, 0, 0, 0) -
                              86400000
                          )
                        }
                        onChange={(newValue) => {
                          field.onChange(newValue);
                          if (getValues().ToDate) {
                            if (
                              +new Date(getValues().ToDate).setHours(
                                0,
                                0,
                                0,
                                0
                              ) > +new Date(newValue).setHours(0, 0, 0, 0)
                            ) {
                              clearErrors("FromDate");
                              clearErrors("ToDate");
                            } else {
                              setError("ToDate", {
                                type: "manual",
                                message:
                                  "End date should be greater than start date",
                              });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={onKeyDown}
                            {...params}
                            style={{ width: "97%" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
              {errors?.["FromDate"] && (
                <p className="errorText">
                  {errors?.["FromDate"]?.message?.toString()}
                </p>
              )}
            </div>
            <div className="col-md-4 p-2">
              <Controller
                name="ToDate"
                control={control}
                defaultValue={new Date(+new Date().setHours(0, 0, 0, 0))}
                render={({ field, ...props }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        orientation="portrait"
                        value={field.value}
                        className="report-date"
                        inputFormat="MM/DD/yyyy"
                        minDate={
                          new Date(
                            +new Date(getValues().FromDate).setHours(
                              0,
                              0,
                              0,
                              0
                            ) + 86400000
                          )
                        }
                        onChange={(newValue) => {
                          field.onChange(newValue);
                          if (getValues().FromDate) {
                            if (
                              new Date(getValues().FromDate).setHours(
                                0,
                                0,
                                0,
                                0
                              ) < new Date(newValue).setHours(0, 0, 0, 0)
                            ) {
                              clearErrors("FromDate");
                              clearErrors("ToDate");
                            } else {
                              setError("ToDate", {
                                type: "manual",
                                message:
                                  "End date should be greater than start date",
                              });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={onKeyDown}
                            {...params}
                            style={{ width: "97%" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
              {errors?.["ToDate"] && (
                <p className="errorText">
                  {errors?.["ToDate"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="pt-3">
            {uhidData === "other" && (
              <React.Fragment>
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(getOTPData)}
                  type="submit"
                  style={{ ...rootButton }}
                  disabled={loading || loadingReport}
                >
                  Get OTP
                </button>
                <div style={{ margin: "10px 0" }}>
                  <input
                    value={otp}
                    onChange={(e) => {
                      if (e?.target?.value?.length > 4) return;
                      setOtp(e?.target?.value);
                    }}
                    type="number"
                    className="form-control p-2"
                    id="email"
                    placeholder="Enter OTP*"
                    name="otp"
                  />
                  {errorOTP && (
                    <p className="errorText">
                      {!otp?.length
                        ? "Enter OTP"
                        : otp?.length < 4 || otp?.length > 4
                        ? "Enter a valid OTP"
                        : ""}
                    </p>
                  )}
                </div>
              </React.Fragment>
            )}
            <button
              className="btn btn-primary"
              onClick={handleSubmit(getFormData)}
              type="submit"
              style={{ ...rootButton }}
              disabled={
                loading || loadingReport || (uhidData === "other" && !validate)
              }
            >
              View Details
            </button>
          </div>
        </div>
        <div>
          {loadingReport || loading ? (
            <div className="loader-background">
              <div className="centerLoader1">
                <Loader />
              </div>
            </div>
          ) : result?.length ? (
            <React.Fragment>
              <p
                className="pt-5"
                style={{ fontWeight: "bold", color: "#FF6F0F" }}
              >
                REPORTS FOR UHID {inputData.UHID} FROM{" "}
                {new Date(inputData.FromDate).toLocaleDateString("en-GB")} to{" "}
                {new Date(inputData.ToDate).toLocaleDateString("en-GB")}
              </p>
              <Table
                Columns={columns}
                rowsData={result}
                currPage={currPage}
                setCurrPage={setCurrPage}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {reportResp?.IsSuccessful && (
                <React.Fragment>
                  <p
                    className="pt-5"
                    style={{ fontWeight: "bold", color: "#FF6F0F" }}
                  >
                    REPORTS FOR UHID {inputData.UHID} FROM{" "}
                    {new Date(inputData.FromDate).toLocaleDateString("en-GB")}{" "}
                    to {new Date(inputData.ToDate).toLocaleDateString("en-GB")}
                  </p>
                  {!result?.length && (
                    <p
                      className=""
                      style={{ color: "#333333", fontSize: "16px" }}
                    >
                      No Record Found
                    </p>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyReport;

// div = "col-md-4 p-1"
// select = "form-select pt-2 pb-2 ps-3 pe-3"
