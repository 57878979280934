import React from "react";

const Tracker_line = ({data}) => {
  // console.log(data,data?.MFD )
  return (
    <div class="timeline-main">
      <ul>
        <li>
          <p className={data?.MFD ? "active-tracker" : ""}>MFD</p>
          <br />
          <span>Patient has been marked for discharge.</span>
        </li>
        <li>
          <p className={data?.SFB ? "active-tracker" : ""}>SFB</p>
          <br />
          <span>Patient file sent for billing.</span>
        </li>
        <li>
          <p className={data?.PC ? "active-tracker" : ""}>PC</p>
          <br />
          <span>Pharmacy clearence done.</span>
        </li>
        <li>
          <p className={data?.FR ? "active-tracker" : ""}>FR</p>
          <br />
          <span>File received at billing.</span>
        </li>
        <li>
          <p className={data?.STA ? "active-tracker" : ""}>STA</p>
          <br />
          <span>Sent to TPA for approval.</span>
        </li>
        <li>
          <p className={data?.BP ? "active-tracker" : ""}>BP</p>
          <br />
          <span>Bill prepared.</span>
        </li>
      </ul>
    </div>
  );
};

export default Tracker_line;
