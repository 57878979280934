import React, { useState } from "react";
import search from "../../../mainIcon/icons/search.png";
import "./rootsearch.css";

export default function RootSearch({
  searchData,
  setsearchData=()=>{},
  borderRadius,
  placeholder,
  onChange,
  disabled
}) {
  return (
    <div
      className="input-group myappointment"
      style={{ border: "1px solid #007DC6", borderRadius: "30px" }}
    >
      <input
              style={{
                borderTopLeftRadius: "30px",
                borderBottomLeftRadius: "30px",
                borderRight: "0px",
                
              }}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        className="form-control border-end-0 border universsalFocus rootselect"
        type="search"
        value={searchData}
        id="example-search-input"
      />
      <span className="input-group-append">
        <button
          style={{
            
            padding: "0.6rem 0.9rem",
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            borderLeft: "0px",
            paddingTop:'12px',
            paddingBottom:'12px',
          }}
          className="btn btn-outline-secondary bg-white border-start-0 border ms-n5 universsalFocus"
          type="button"
        >
          <img draggable='false' src={search} alt="search" width={20} />
        </button>
      </span>
    </div>
  );
}
