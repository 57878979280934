import { rootButton } from "assets/util/common/root";
import React from "react";

const ValidateOther = ({
  register,
  setValue,
  setCurrUHID = () => {},
  currUHID,
  errors,
  loading,
  otp,
  setOtp = () => {},
  handleSubmit,
  getOTPData,
  errorOTP,
  name
}) => {
  return (
    <div>
      <div className="col-md-4 p-2">
        <input
          pattern="\d{9}"
          maxLength={9}
          min={0}
          max={999999999}
          {...register(name, {
            onChange: (e) => {
              if (e?.target?.value?.length > 9) {
                setValue(name, currUHID);
                return;
              }
              setCurrUHID(e?.target?.value);
            },
          })}
          type="number"
          className="form-control p-2"
          id="email"
          placeholder="Enter UHID*"
          name={name}
        />
        {errors[name] && (
          <p className="errorText">{errors[name]?.message?.toString()}</p>
        )}
      </div>
      <React.Fragment>
        <button
          className="btn btn-primary"
          onClick={handleSubmit(getOTPData)}
          type="submit"
          style={{ ...rootButton }}
          disabled={loading}
        >
          Get OTP
        </button>
        <div style={{ margin: "10px 0" }}>
          <input
            value={otp}
            onChange={(e) => {
              if (e?.target?.value?.length > 4) return;
              setOtp(e?.target?.value);
            }}
            type="number"
            min={0}
            max={9999}
            className="form-control p-2"
            id="email"
            placeholder="Enter OTP*"
            name="otp"
          />
          {errorOTP && (
            <p className="errorText">
              {!otp?.length
                ? "Enter OTP"
                : otp?.length < 4 || otp?.length > 4
                ? "Enter a valid OTP"
                : ""}
            </p>
          )}
        </div>
      </React.Fragment>
    </div>
  );
};

export default ValidateOther;
