
const initilaState = []



export const R_getallspecialties = (state=initilaState,action) =>{

    switch (action.type) {
        case "GET_ALL_SPECIALITIES":
            return [...action.payload]
    
        default:
            return state
    }

}