import { postAPIData } from "Hooks/Services";
import { cardOrientation, rootButton } from "assets/util/common/root";
import { callGet } from "common/local";
import React, { useEffect, useState } from "react";
import back from "../../assets/mainIcon/icons/Vector.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "util/API/api";
import Loader from "Pages/Loader";
const PaymentHistoryDetails = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const authData = callGet("auth");
  const params = new URLSearchParams(location?.search);
  const [detailsData, setDetailsData] = useState({});
  const icon = { transform: "rotate(90deg)" };
  const getDetailsData = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({
        ...API?.getPaymentHistoryDetails({
          PaymentId: data?.paymentId,
          TransactionId: data?.transactionId,
          ...authData,
        }),
      });
      if (resp?.data?.IsSuccessful) {
        setDetailsData(resp?.data?.Data?.[0]);
      } else {
        throw resp?.data;
      }
    } catch (err) {
      // toast.error(err?.FriendlyMessage || "Something went wrong");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (params?.get("transactionId") && params?.get("paymentId")) {
      getDetailsData({
        paymentId: params?.get("paymentId"),
        transactionId: params?.get("transactionId"),
      });
    }
  }, [params?.get("transactionId"), params?.get("paymentId")]);

  if (!params?.get("paymentId") || !params?.get("transactionId")) {
    navigate("/dashboard");
  }
  return (
    <div>
      <div className="--header mt-3 p-5" style={{ ...cardOrientation }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h4>Payment History Detail</h4>
          <button
            className="btn-text-center"
            onClick={() => navigate("/dashboard/paymentHistory")}
          >
            <img style={icon} draggable="false" src={back} alt="go back" />
            <p className="back-btn-text">Go Back</p>
          </button>
        </div>
        {loading && (
          <div className="loader-background">
            <div className="centerLoader1">
              <Loader />
            </div>
          </div>
        )}
        {!loading && detailsData?.UHID && (
          <div className="row mb-5">
            <div className="col-12">
              <table className="mt-3" style={{ width: "600px" }}>
                <tr style={{ lineHeight: "30px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Receipt No.:
                  </td>
                  <td style={{ fontWeight: "500" }}>
                    {" "}
                    {detailsData?.InvoiceNumber || "----"}
                  </td>
                </tr>
                <tr style={{ lineHeight: "30px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>UHID:</td>
                  <td style={{ fontWeight: "500" }}>
                    {" "}
                    {detailsData?.UHID || "----"}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Patient Name:
                  </td>
                  <td style={{ fontWeight: "500", color: "#FF6F0F" }}>
                    {" "}
                    {detailsData?.PatientName}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>Type:</td>
                  <td style={{ opacity: ".7" }}>
                    {" "}
                    {detailsData?.Title || "----"}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Amount:
                  </td>
                  <td style={{ opacity: ".7" }}> {detailsData?.Amount? "₹" + detailsData?.Amount : "00.00"}</td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Hospital Name:
                  </td>
                  <td style={{ opacity: ".7" }}>
                    {" "}
                    {detailsData?.HospitalName || "----"}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Doctor Name:
                  </td>
                  <td style={{ opacity: ".7" }}>
                    {" "}
                    {detailsData?.DoctorName || "----"}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    AppointmentId:
                  </td>
                  <td style={{ opacity: ".7" }}>
                    {detailsData?.AppointmentId ? (
                      <p
                        style={{
                          color: "#FF6F0F",
                          // textDecoration: "underline",
                          // cursor: "pointer",
                        }}
                        // onClick={() => {
                        //   navigate(
                        //     `/dashboard/myAppointment?id=${detailsData?.AppointmentId}`
                        //   );
                        // }}
                      >
                        {detailsData?.AppointmentId}
                      </p>
                    ) : (
                      "----"
                    )}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Transaction Id:
                  </td>
                  <td style={{ opacity: ".7" }}>
                    {" "}
                    {detailsData?.TransactionID || "----"}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Transaction On:
                  </td>
                  <td style={{ opacity: ".7" }}>
                    {" "}
                    {detailsData?.TransactionOn || "----"}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Transaction Status:
                  </td>
                  <td
                    style={{
                      opacity: ".7",
                      color: detailsData?.TransactionStatus ? "green" : "gray",
                      fontSize: "22px",
                      fontWeight: "600",
                    }}
                  >
                    {detailsData?.TransactionStatus ? "Success" : "Pending"}
                  </td>
                </tr>
                <tr style={{ lineHeight: "40px" }}>
                  <td style={{ color: "#007DC6", fontWeight: "500" }}>
                    Source:
                  </td>
                  <td style={{ opacity: ".7" }}>
                    {" "}
                    {detailsData?.Source || "----"}
                  </td>
                </tr>
              </table>
              {detailsData?.InvoiceURL && (
                <div className="row">
                  <div className="col">
                    <button
                      onClick={() => {
                        window.open(detailsData?.InvoiceURL, "_blank");
                      }}
                      className="btn"
                      style={{ ...rootButton, marginTop: "15px" }}
                    >
                      View Receipt
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {!loading && !detailsData?.UHID && <div>No Data Found</div>}
      </div>
    </div>
  );
};

export default PaymentHistoryDetails;
