import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { rootButton } from "../util/common/root";

export function LoadingButton({
  name,
  loading,
  style,
  onClick,
  disabled,
  color,
  textColor,
}) {
  return (
    <Button
      variant="primary"
      type="submit"
      onClick={onClick}
      style={{
        ...rootButton,
        width: "12rem",
        ...style,
        background: `${color ? color : ""}`,
        color: `${textColor ? textColor : ""}`,
      }}
      disabled={loading || disabled}
    >
      { name}
    </Button>
  );
}
