import axios from "axios";
import { SERVER } from "../../server/server";
import { action_type } from "../actionType/actionType";

export const postThunk = ({ url, body, key }) => {
  var { call, stop, data } = action_type?.[key];
  return async (dispatch, getState) => {
    try {
      dispatch({ type: call });
      const resp = await axios.post(`${SERVER}/${url}`, body);
      let result = JSON.parse(resp.data);
      if (result?.IsSuccessful) {
        if (!result?.Data?.length && result?.FriendlyMessage) {
          dispatch({ type: data, payload: [], err: result?.FriendlyMessage });
          throw result;
        }
        dispatch({ type: data, payload: result?.Data, err: "" });
      } else {
        dispatch({ type: data, payload: [], err: result?.FriendlyMessage });
        throw result;
      }
    } catch (err) {
      console.error(err?.FriendlyMessage, err);
    } finally {
      dispatch({ type: stop });
    }
  };
};
