import React from "react";
import "./index.css";
import { Icon } from "@iconify/react";
import back from "../../../../../../../assets/mainIcon/icons/Vector.png";
import { cardOrientation } from "assets/util/common/root";
import { Navigate, useNavigate } from "react-router-dom";
const AllCoupons = ({
  AllCoupponsData,
  showCoupons,
  setShowCoupons = () => {},
  selectedCoupon,
  setSelectedCoupon = () => {},
}) => {
  const navigation = useNavigate();
  const icon = { transform: "rotate(90deg)" };
  // const AllCoupponsData = useSelector((state) => state.R_getAllCoupons);

  return (
    <div
      className="patient_header mt-3 p-5 mb-3 counpons-container"
      style={{ ...cardOrientation, marginBottom: "30px" }}
    >
      <div
        className="pateinet-detail mt-3"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <h4>All Coupons</h4>{" "}
        <button
          className="btn-text-center"
          onClick={() => {
            setShowCoupons((a) => !a);
          }}
        >
          <img style={icon} draggable="false" src={back} alt="go back" />
          <p className="back-btn-text">Go Back</p>
        </button>
      </div>
      {/* <div className="coupon-container">
        <input
          value={couponValue}
          onChange={(e) => {
            setCouponValue(e.target.value);
          }}
          type="text"
          style={{ paddingLeft: "10px" }}
          placeholder="Enter voucher code"
        />
        <button
          onClick={() => {
            if (!couponValue) {
              toast.error("Enter your coupon");
              return;
            }
            let validCoupon = couponsData?.find(
              (a) => a?.CouponCode == couponValue
            );
            if (validCoupon) {
              console.log("valid");
            } else {
              toast.error("Inavlid coupon");
            }
            console.log(validCoupon, "--------------->WER");
          }}
        >
          Apply
        </button>
      </div> */}
      {AllCoupponsData?.length &&
        AllCoupponsData?.map((e, i) => {
          return (
            <div key={i} className="coupons-list">
              <div className="coupon-offer">
                {" "}
                <b>{e?.Discount}</b>
              </div>
              <div className="coupon-description">
                <p>
                  Avail{" "}
                  {e?.DiscountType === "Percentage"
                    ? `${e?.Discount}`
                    : `${e?.Discount}`}{" "}
                  on doctor ref appointments booked through
                  {/* Avail 10% discount on doctor appointments booked through re3tf
                  rfr e */}
                </p>
                <div className="coupon-info-container">
                  <div className="coupon-valida-till">
                    <p>Offer Valid till {e?.ValidTill}</p>
                  </div>
                  <div className="info-icon">
                    <Icon
                      onClick={() => {
                        navigation(e?.DescriptionUrl);
                      }}
                      fontSize={"25px"}
                      icon="mdi:question-mark-circle"
                    />
                  </div>
                </div>
                <div className="apply-buttons">
                  <button className="coupon-code">{e?.CouponCode || ""}</button>
                  <button
                    className="coupon-apply"
                    onClick={() => {
                      setSelectedCoupon({
                        CouponCode: e?.CouponCode,
                        CouponId: e?.CouponId,
                      });
                      setShowCoupons((a) => !a);
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AllCoupons;
