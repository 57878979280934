import React, { useEffect, useState } from "react";
import "./AppoinmentTimeDetails.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "Action/action";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TimingDetailsSchema } from "Pages/Appointment/AppointmentCategories/utils";
import { toast } from "react-hot-toast";
import { POLICY_BASE_URL } from "server/server";
import { callGet } from "common/local";
const checkButton = (type, selected, reSelected) => {
  if (type === "TS" && selected >= 0 && reSelected >= 0) {
    return true;
  }
  if (type === "OS" && selected >= 0) {
    return true;
  }
  return false;
};
const AppoinmentTimeDetails = ({ takeAppointment, data }) => {
  const [selected, setSelected] = useState(-1);
  const [takeSlot, setSlot] = useState("");
  const userData = callGet("auth");
  const [reSelected, setReSelected] = useState(-1);
  const [timingDetails, setTimingsDetails] = useState({});
  const [reScheduleSlot, setRescheduleSlot] = useState("");
  const docDetails = useSelector(
    (state) => state.R_getDocsTimming?.doctorDetail
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      ...timingDetails,
      slot: {},
      reScheduleSlot: {},
      type: takeAppointment?.AppointmentTypeCode,
      checkbox: false,
    },
    resolver: yupResolver(TimingDetailsSchema),
  });
  const getFormData = (data) => {
    if (data?.type === "TS") {
      dispatch(
        actions.A_savePatientAppoint({
          slot: data?.slot,
          reScheduleSlot: data?.reScheduleSlot,
          doctorDetail: docDetails,
          takeAppointment: takeAppointment,
        })
      );
    } else {
      dispatch(
        actions.A_savePatientAppoint({
          slot: data?.slot,
          doctorDetail: docDetails,
          takeAppointment: takeAppointment,
        })
      );
    }
    navigate("patientName");
    reset();
  };
  return (
    <div className="container-fluid mt-3">
      <div className="d-flex justify-content-satrt flex-wrap px-3">
        {data?.Timings?.length > 0 &&
          data?.Timings?.map((time, index) => (
            <Controller
              key={index}
              control={control}
              name="slot"
              render={({ field: { onChange, value } }) => (
                <div
                  className="me-2 mb-2"
                  key={index}
                  onClick={() => {
                    if (selected === index) {
                      onChange({});
                      setSelected(-1);
                      setSlot("");
                      return;
                    }
                    setSlot(time);
                    onChange(time);
                    setSelected(index);
                  }}
                >
                  <button
                    id="time_pills"
                    className={
                      selected === index
                        ? "btn text-center rounded-pill bg-primary"
                        : "btn text-center rounded-pill"
                    }
                  >
                    {time.TimeSlot}
                  </button>
                </div>
              )}
            />
          ))}
      </div>
      {errors?.["slot"] && (
        <p className="errorText">
          {errors?.["slot"]?.Interval?.message?.toString()}
        </p>
      )}
      {takeAppointment?.AppointmentTypeCode === "TS" &&
        data?.ReScheduleTimings?.length > 0 && (
          <React.Fragment>
            <div>Re-scheduling slots</div>
            <p>
              Please select alternate slot in case of doctor emergency on
              appointment time
            </p>
            <div className="d-flex justify-content-satrt flex-wrap px-3">
              {data?.ReScheduleTimings?.length > 0 &&
                data?.ReScheduleTimings?.map((time, index) => (
                  <Controller
                    control={control}
                    key={index}
                    name="reScheduleSlot"
                    render={({ field: { onChange, value } }) => (
                      <div
                        className="me-2 mb-2"
                        onClick={() => {
                          if (reSelected === index) {
                            onChange({});
                            setReSelected(-1);
                            setRescheduleSlot("");
                            return;
                          }
                          if (
                            time?.TimeSlot?.replaceAll(":", "") <
                            getValues("slot")?.TimeSlot?.replaceAll(":", "")
                          ) {
                            toast.error(
                              "Reschedule slot should be greater than seleceted slot"
                            );
                            onChange({});
                            setReSelected(-1);
                            setRescheduleSlot("");
                            return;
                          }
                          setRescheduleSlot(time);
                          onChange(time);
                          setReSelected(index);
                        }}
                      >
                        <button
                          id="time_pills"
                          className={
                            reSelected === index
                              ? "btn text-center rounded-pill bg-primary"
                              : "btn text-center rounded-pill"
                          }
                        >
                          {time?.TimeSlot}
                        </button>
                      </div>
                    )}
                  />
                ))}
            </div>
            {errors?.["reScheduleSlot"] && (
              <p className="errorText">
                {errors?.["reScheduleSlot"]?.Interval?.message?.toString()}
              </p>
            )}
          </React.Fragment>
        )}
      {takeAppointment?.AppointmentTypeCode === "TS" && (
        <div className="form-check mt-3 mb-3">
          <input
            {...register("checkbox")}
            type="checkbox"
            name="checkbox"
            style={{ cursor: "pointer" }}
            className="form-check-input"
            id="exampleCheck1"
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            I agree with this{" "}
            <span
              onClick={() => {
                navigate("/dashboard/tele-consultation-policy");
              }}
            >
              consent for teleconsultation
            </span>
          </label>
          {errors?.["checkbox"] && (
            <p className="errorText">
              {errors?.["checkbox"]?.message?.toString()}
            </p>
          )}
        </div>
      )}
      <div className="text-center mt-5" onClick={handleSubmit(getFormData)}>
        <button
          id="time_pills"
          className={"btn ps-5 pe-5 text-center rounded-pill bg-primary"}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default AppoinmentTimeDetails;
