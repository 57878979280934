const initilaState = {
  loading: false,
  doctorDetail: {},
  data: [],
};

export const R_getDocsTimming = (state = initilaState, action) => {
  switch (action.type) {
    case "DTIME_BY_ID_CALL":
      return { ...state, loading: true };

    case "DTIME_BY_ID_STOP":
      return { ...state, loading: false };

    case "GET_DTIME_BY_ID":
      return { ...state, data: action.payload };
    case "SET_DOC_DET":
      return {
        ...state,
        loading: true,
        doctorDetail: action.payload,
        data: [],
      };

    default:
      return state;
  }
};
