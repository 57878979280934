export const rootButton = {
    padding:"8px 50px",
    borderRadius:"25px",
    backgroundColor:"#007DC6",
    border:"none",
    color:"white",
    fontSize:"16px"
}
// import img from '../../mainIcon/icons/Vector.png'
export const font = {
    fontFamily:"'Gotham Black', sans-serif"
}
export const linkStyle = {color:"#FF6F0F",cursor:'pointer'}
export const inputStyle = {
    border: "1px solid #007DC6",
    borderRadius: "10px",
    fontSize:"1rem"
}
export const selectInputStyle = {
    width:"272px",
    border: "1px solid #007DC6",
    // overflow:"hidden",
    borderRadius: "10px",
    fontSize:"1rem",
    background:"url(../../mainIcon/icons/Vector.png) 240px 4px no-repeat #fff"
}
export const cardOrientation = {
    minHeight:"93%",
    borderRadius:"10px",
    backgroundColor:"rgba(255, 255, 255, 0.8)"
}
