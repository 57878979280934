const initilaState = [];

export const R_getAllCoupons = (state = initilaState, action) => {
  switch (action.type) {
    case "GET_ALL_COUPONS":
      return action.payload;

    default:
      return state;
  }
};
