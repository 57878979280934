import * as yup from "yup";
export const filterByDcotorName = (data, name) => {
  let Arr = [];
  name = name?.trim()?.toLocaleLowerCase();
  for (let key of data) {
    for (let e of key?.DoctorList) {
      if (
        e?.DoctorName?.trim()
          ?.toLocaleLowerCase()
          ?.includes(name?.trim()?.toLocaleLowerCase())
      ) {
        Arr.push(e);
      }
    }
  }
  return [...new Set(Arr)];
};

export const filterBySpecialityrName = (data, name) => {
  let Arr = [];
  name = name?.trim()?.toLocaleLowerCase();
  //SpecialisationName
  for (let key of data) {
    if (key?.SpecialisationName?.trim()?.toLocaleLowerCase()?.includes(name)) {
      Arr.push(key);
    }
  }
  return [...new Set(Arr)];
};

export const TimingDetailsSchema = yup
  .object()
  .shape({
    slot: yup.object().shape({
      Interval: yup.string().required("Slot is required"),
      SlotId: yup.string().required(""),
      TimeSlot: yup.string().required(""),
    }),
  })
  .when((values, schema) => {
    if (values?.type === "TS") {
      return schema?.shape({
        checkbox: yup
          .bool()
          .required("This field is required")
          .test("check", "This field is required", (val) => val === true),
        reScheduleSlot: yup.object().shape({
          Interval: yup.string().required("Reschedule slot is required"),
          SlotId: yup.string().required(""),
          TimeSlot: yup.string().required(""),
        }),
      });
    }
  });
