import React from "react";
import { cardOrientation } from "../../assets/util/common/cardOrientation";
import { callGet } from "common/local";
export default function Sample({ page }) {
  const sampleCard = { ...cardOrientation, minHeight: "40%" };
  const userData = callGet("auth");
  return (
    <React.Fragment>
      <div
        className="container-fluid p-5 mt-2 payment-summary"
        style={sampleCard}
      >
        <h2 style={{ color: "#007DC6" }}>
          <b>
            {userData?.hospitalName?.split(",")?.join(", ") ||
              "Marengo Asia Hospitals, Faridabad"}
          </b>{" "}
        </h2>
        {page === "Ambulance" && (
          <React.Fragment>
            <p className="mt-3 mb-3">
              Call Us{" "}
              <b style={{ color: "#FF6F0F" }}>
                {" "}
                {userData?.FacilityInfo?.EmergencyNumber || ""}{" "}
              </b>
              in case of any Emergency/Ambulance service at{" "}
              {userData?.hospitalName?.split(",")?.[0] ||
                "Marengo QRG Hospital"}
              ,{" "}
              {userData?.hospitalName?.split(",")?.[1]?.trim() ===
                "Faridabad" && "Sec-16,"}{" "}
              {userData?.hospitalName?.split(",")?.[1] || "Faridabad"}
            </p>
          </React.Fragment>
        )}
        {page === "HomeSample" && (
          <React.Fragment>
            <p className="mt-3 mb-3">
              For Home Sample Collection, Call Us at{" "}
              <b style={{ color: "#FF6F0F" }}>
                {userData?.FacilityInfo?.HomeSampleCollectionNumber || ""}
              </b>{" "}
              -{" "}
              {userData?.hospitalName?.split(",")?.[0] ||
                "Marengo QRG Hospital"}
              ,{" "}
              {userData?.hospitalName?.split(",")?.[1]?.trim() ===
                "Faridabad" && "Sec-16,"}{" "}
              {userData?.hospitalName?.split(",")?.[1] || "Faridabad"}
            </p>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
