export const sidebarData = [
  {
    title: "Home",
    value: "dashboard",
    path: "/dashboard",
  },
  {
    title: "Book An Appointment",
    value: "book-appointment",
    path: "book-appointment",
  },
  {
    title: "Online Payment",
    value: "Payment",
    path: "Payment",
  },
  {
    title: "Register with us",
    value: "patient-registration",
    path: "patient-registration",
  },
  {
    title: "preventive health check up",
    value: "preventive",
    path: "preventive",
  },
  {
    title: "My Reports",
    value: "MyReport",
    path: "MyReport",
  },
  {
    title: "Home sample collection",
    value: "Sample-collection",
    path: "Sample-collection",
  },
  {
    title: "Specialties",
    value: "Specialties",
    path: "specialties",
  },
  {
    title: "Questions? Ask us",
    value: "QuestionAsk",
    path: "QuestionAsk",
  },
  {
    title: "Immunisation Record",
    value: "Immunisation",
    path: "Immunisation",
  },
  {
    title: "IPD Discharge Tracker",
    value: "IpdTracker",
    path: "IpdTracker",
  },
  {
    title: "Feedback",
    value: "feedback",
    path: "feedback",
  },
  {
    title: "My Appointments",
    value: "myAppointment",
    path: "myAppointment",
  },
  {
    title: "Payment History",
    value: "Payment-History",
    path: "paymentHistory",
  },
  {
    title: "Terms & Conditions",
    value: "Terms-&-Conditions",
    path: "termsConditions",
  },
  {
    title: "Privacy Policy",
    value: "Privacy-Policy",
    path: "privacyPolicy",
  },
];
