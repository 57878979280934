import * as React from 'react';
import Modal from '@mui/material/Modal';



export default function MainModal({open,handleClose,Component,props}) {

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <React.Fragment >
          <Component props={props} handleClose={handleClose}/>
        </React.Fragment>

      </Modal>
    </div>
  );
}