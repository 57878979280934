import { imgUrl } from "../server/server"
import dummyDoc from "../assets/image/doctor.png"

export function getImg(img){
    let imageURL
    if(img){
        imageURL = `${imgUrl()}/${img}`
    }else{
        imageURL=dummyDoc
    }
    return imageURL
}