import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { postThunk } from "Action/middleware/thunk";
import MainIcon from "assets/mainIcon/MainIcon";
import { cardOrientation, rootButton } from "assets/util/common/root";
import { anteMeridiemFormat } from "common/days";
import { getImg } from "common/imageUrl";
import { callGet, callSet } from "common/local";
import API from "util/API/api";
import "./ViewPrifile.css";
import { useSearchParams } from "react-router-dom";
import Loader from "Pages/Loader";
import { postAPIData } from "Hooks/Services";

function ViewProfile() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [doctorProfileLoading, setDcotorProfileLoading] = useState(false);
  const doctorProfile = useSelector((state) => state.R_getdoctorprofile);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = callGet("auth");
  const [takeAppointment, setTakeAppointment] = useState({
    DoctorId: doctorProfile?.data?.[0]?.DoctorId || "",
    FacilityId:
      doctorProfile?.data?.[0]?.Facilities?.HospitalId ||
      authData?.selectedHospitalId,
    Date: moment().format("YYYY-MM-D"),
    ...authData,
  });
  const getDoctorProfile = async (data) => {
    try {
      setDcotorProfileLoading(true);
      const resp = await postAPIData({ ...API.getDcotorProfile(data) });
      const respData = JSON.parse(resp?.data);
      dispatch({ type: "CLEAR_TAKE_APPOINT" });
      dispatch({ type: "GET_DOC_PRO", payload: respData?.Data, err: "" });
    } catch (err) {
      console.error(err);
    } finally {
      setDcotorProfileLoading(false);
    }
  };
  function handleAppointment(bool) {
    if (doctorProfile?.data?.[0]?.DoctorId || false) {
      var payload = {
        Designation: doctorProfile?.data[0]?.Designation || "",
        DoctorId: doctorProfile?.data[0]?.DoctorId || "",
        DoctorName: doctorProfile?.data?.[0]?.DoctorName || "",
        DoctorPic: doctorProfile?.data?.[0]?.Pic || "",
        HospitalId:
          doctorProfile?.data?.[0]?.Facilities?.HospitalId ||
          authData?.selectedHospitalId ||
          "",
        SpecialisationId: doctorProfile?.data?.[0]?.SpecialisationId || "",
        SpecialisationName: doctorProfile?.data?.[0]?.SpecialisationName || "",
      };
      dispatch({ type: "SET_DOC_DET", payload });
      dispatch(
        postThunk(
          API.A_getDocsTimming({
            ...takeAppointment,
            DoctorId: doctorProfile?.data?.[0]?.DoctorId || "",
          })
        )
      );
      if (bool) {
        navigate("/dashboard", { replace: true });
      }
      navigate(
        location?.pathname &&
          location?.pathname?.split("doctList")?.[0] + "doctList/appointDoc"
      );
    } else {
      toast.error("No Data Found");
    }
  }
  useEffect(() => {
    if (searchParams?.get("id")?.length && doctorProfile?.data?.[0]?.DoctorId) {
      handleAppointment(true);
    }
  }, [searchParams?.get("id"), doctorProfile?.data?.[0]?.DoctorId]);
  useEffect(() => {
    window.scroll(0, 0);
    if (searchParams?.get("id")) {
      callSet({
        name: "auth",
        data: {
          ...authData,
          selectedHospitalId: searchParams?.get("id")?.split(",")?.[1],
        },
      });
      getDoctorProfile({
        ...authData,
        DoctorId: searchParams?.get("id")?.split(",")?.[0],
        FacilityId: searchParams?.get("id")?.split(",")?.[1],
      });
    }
  }, [searchParams?.get("id")]);
  // console.log(doctorProfile)
  if (
    !doctorProfile?.data?.length &&
    !doctorProfile?.loading &&
    !doctorProfileLoading
  )
    return (
      <div className="--header">
        <h4>Doctor Profile</h4>
        <p>No Data Found</p>
      </div>
    );
  return doctorProfile?.loading || doctorProfileLoading ? (
    <div className="centerLoader1">
      <Loader />
    </div>
  ) : (
    <div
      className="container-fluid mt-2  p-4 "
      style={{ ...cardOrientation, position: "relative" }}
    >
      <MainIcon bottom={0} right={20} width={300} zIndex={-1} />
      <div className="row 2">
        <div className=" d-flex align-items-center">
          <div>
            <div className="media d-flex doctor-profile">
              <img
                id="profile"
                draggable="false"
                src={getImg(
                  doctorProfile?.data?.length ? doctorProfile?.data[0].Pic : ""
                )}
                alt=""
                width="60"
                height="60"
              />
              <div className="media-body ms-3 ">
                <h6 className="mt-0 doctor-heading">
                  <b>
                    {doctorProfile?.data?.length
                      ? doctorProfile?.data?.[0]?.DoctorName
                      : "Dr. Sanjeev Kumar"}
                  </b>
                </h6>

                <p>
                  {doctorProfile?.data.length
                    ? doctorProfile?.data[0]?.Designation
                    : "HOD"}{" "}
                  -{" "}
                  {doctorProfile?.data.length
                    ? doctorProfile?.data[0].SpecialisationName
                    : "Internal Medicine"}
                </p>
                <p>
                  {doctorProfile?.data.length
                    ? doctorProfile?.data[0]?.Facilities.length
                      ? doctorProfile?.data[0]?.Facilities[0]?.HospitalName
                      : "No Data"
                    : "No Data"}
                </p>
                {/* <p>Marengo Asia Hospital,Faridabad</p> */}
              </div>
            </div>
          </div>
          <div className="ms-auto">
            <button onClick={handleAppointment} style={rootButton}>
              Book an Appointment
            </button>
          </div>
        </div>
      </div>
      <div className=" doctortiming justify-content-between align-items-center mb-4 doctortiming">
        <span id="timing">Doctor Timings</span>
        {doctorProfile?.data.length
          ? doctorProfile?.data?.[0]?.Facilities?.length
            ? doctorProfile?.data?.[0]?.Facilities[0]?.Schedule?.length
              ? doctorProfile?.data?.[0]?.Facilities[0]?.Schedule?.map(
                  (data, i) => (
                    <span
                      key={i}
                      className="text-color"
                      style={{ fontSize: "12px" }}
                    >
                      {data?.ShiftFrom && (
                        <React.Fragment>
                          {data?.Day} {anteMeridiemFormat(data?.ShiftFrom)} TO{" "}
                          {anteMeridiemFormat(data?.ShiftTo)}
                        </React.Fragment>
                      )}
                    </span>
                  )
                )
              : ""
            : ""
          : ""}
      </div>
      <hr />

      <div className="row ">
        <div className="col-md-9 doctor-profile-description">
          {doctorProfile?.data.length ? (
            <div
              dangerouslySetInnerHTML={{
                __html: doctorProfile?.data[0]?.ProfileData,
              }}
            ></div>
          ) : (
            <React.Fragment>
              <h4>Profile:</h4>
              <p id="profile-para">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
                libero accusantium fuga corporis distinctio eius incidunt! Vero
                veritatis nihil pariatur, similique facere, cum aliquid
                perspiciatis sequi hic ad amet quo.
              </p>
              <div className="language">
                <h4>Languages Spoken:</h4>
                <p>Hindi,English</p>

                <h4>Education and Fellowship</h4>
                <ul type="disc">
                  <li>MBBS</li>
                  <li>MD</li>
                </ul>

                <h4>Professional Membership</h4>
                <ul type="disc">
                  <li>
                    Research Society for the study of diabetes in india (RSSDI)
                  </li>
                  <li>API</li>
                  <li>Indian Rheumatology ASSP Indian Critical Care Society</li>
                </ul>
                <h4>Specialty Interests</h4>
                <ul type="disc">
                  <li>Internal Medicine</li>
                </ul>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewProfile;
