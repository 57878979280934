import React, { useEffect, useState } from "react";
import "./DoctorCard.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import API from "util/API/api";
import { postThunk } from "Action/middleware/thunk";
import { callGet } from "common/local";
import { getImg } from "common/imageUrl";

// {'Type':'getdoctorstimings','DoctorId':req.body.DoctorId,'FacilityId':2,'Date':req.body.Date,'AppointmentTypeID':2}

// https://patientappqa.marengoasia.com/Files/Doctor/Imagename

const DoctorCard = ({ data, date }) => {
  const location = useLocation();
  const authData = callGet("auth");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [takeAppointment, setTakeAppointment] = useState({
    DoctorId: data.DoctorId,
    FacilityId: authData?.selectedHospitalId,
    Date: date ? date : moment().format("YYYY-MM-D"),
    ...authData,
  });

  function handleProfile() {
    const authData = callGet("auth");
    var body = {
      ...authData,
      DoctorId: data.DoctorId,
      FacilityId: authData?.selectedHospitalId || authData?.hospitalId,
    };
    dispatch(postThunk(API.A_getdoctorprofile(body)));
    navigate("/dashboard/book-appointment/doctList/view-profile");
  }

  function handleAppointment() {
    dispatch({ type: "SET_DOC_DET", payload: data });
    dispatch(
      postThunk(
        API.A_getDocsTimming({ ...takeAppointment, AppointmentTypeID: 2 })
      )
    );
    navigate(
      (location?.pathname?.includes("doctList")
        ? location?.pathname?.split("doctList")?.[0]
        : "/dashboard/book-appointment/") + "doctList/appointDoc"
    );
  }
// console.log(data, date, '--------------->sdfghnjmk,')
  return (
    <div className="col-xl-3 col-md-4 col-sm-6 py-3">
      <div className="card border-0 shadow text-center doctordetaildiv">
        <div className="card-title mt-2">
          <img
            id="doctor-profile"
            draggable="false"
            src={getImg(data?.DoctorPic || "")}
            alt="doctor-pic"
          />
        </div>
        <div className="card-body d-flex flex-column justify-content-between">
          <div className="Doct_descript mb-4">
            <h6 style={{ color: "#1A2352" }}>{data?.DoctorName}</h6>
            <p style={{ fontSize: "14px" }}>
              {!data?.Designation ? data.SpecialisationName : data?.Designation}
            </p>
          </div>

          <div className="book_appntment d-flex flex-column mb-2">
            <button
              onClick={handleAppointment}
              id="apnmnt_btn"
              className="btn btn-primary rounded-pill mb-2"
              style={{ fontSize: ".9rem" }}
            >
              Book an Appointment
              <span className="float-end">
                <HiArrowNarrowRight />
              </span>
            </button>
            <button
              onClick={handleProfile}
              id="profile_btn"
              className="btn btn-outline-primary rounded-pill"
              style={{ fontSize: ".8rem" }}
            >
              View Profile
              <span className="float-end">
                <HiArrowNarrowRight />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
