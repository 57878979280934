import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { delLocal } from "../common/local";

export default function useLogOut() {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [call, callLogout] = useState("");
  const [status, setStatus] = useState(false);
  function userLogout() {
    delLocal("auth");
    if (params?.get("id")?.split(",")?.length === 2) {
      navigate(
        `/?returnurl=dashboard/book-appointment/doctList/view-profile?id=${params?.get(
          "id"
        )}`
      );
    } else {
      if (
        ["/dashboard/MyReport", "/dashboard/Payment"]?.includes(
          location.pathname
        )
      ) {
        navigate("/?returnurl=" + location.pathname?.substring(1));
      } else {
        navigate("/");
      }
    }
    setStatus(true);
    dispatch({ type: "USER_LOGOUT" });
  }
  useEffect(() => {
    if (call) userLogout();
  }, [call]);
  return [status, callLogout];
}
