import MainIcon from "assets/mainIcon/MainIcon";
import { rootButton } from "assets/util/common/button";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Sucess = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isSuccess = searchParams?.get("success") === "true" ? true : false;
  const appId = searchParams?.get("id") || "";
  const typeData = searchParams?.get("type") || "";
  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <MainIcon top={15} left={20} width={350} />
      </div>
      <div style={{ paddingTop: "23%", paddingLeft: "22%" }}>
        <h1>
          <b style={{ color: "#1A2352", fontSize: "50px" }}>
            <React.Fragment>
              {isSuccess ? (
                <React.Fragment>
                  {
                    {
                      appointment: (
                        <React.Fragment>
                          Appointment Created Successfully !!
                        </React.Fragment>
                      ),
                      payment: (
                        <React.Fragment>Payment Successfull !!</React.Fragment>
                      ),
                    }[typeData]
                  }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  OOPS!! Payment failed. <br /> Please try again later.
                </React.Fragment>
              )}
            </React.Fragment>
          </b>
        </h1>
        {isSuccess && appId && (
          <p>
            Your appointment Id is   {appId}
           {/* { <b
              style={{
                color: "#FF6F0F",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/dashboard", { replace: true });
                navigate(`/dashboard/myAppointment?id=${appId}`);
              }}
            >
              {appId}
            </b> */}
          </p>
        )}
        {isSuccess && (
          <div>
            <button
              onClick={() => {
                navigate("/dashboard", { replace: true });
                navigate(
                  typeData === "appointment"
                    ? `/dashboard/myAppointment`
                    : "/dashboard/paymentHistory"
                );
              }}
              type="submit"
              style={{ ...rootButton, marginTop: "20px" }}
            >
              <b>
                {typeData === "appointment"
                  ? "View All Appointments"
                  : "View All Payments"}
              </b>
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Sucess;
