import { combineReducers } from "redux";
import { R_getPatientLogin } from "../AllReducer/R_getPatientLogin";
import { R_getallspecialties } from "../AllReducer/R_getallspecialties";
import { R_GetDoctorsListBySpecialityID } from "../AllReducer/R_GetDoctorsListBySpecialityID";
import { R_getDocsTimming } from "../AllReducer/R_getDocsTimming";
import { R_savePatientAppoint } from "../AllReducer/R_savePatientAppoint";
import { R_getdoctorprofile } from "../AllReducer/R_getdoctorprofile";
import { R_getdoctorfees } from "../AllReducer/R_getdoctorfees";
import { R_takeappointment } from "../AllReducer/R_takeappointment";
import { R_getpreventivehealthcheckups } from "../AllReducer/R_getpreventivehealthcheckups";
import { R_paymentDetails } from "./../AllReducer/R.PaymentDetails";
import { R_searchText } from "./../AllReducer/R_SeachText";
import { R_getAllCoupons } from "./../AllReducer/R_allCoupons";
import { R_AppointmentDetails } from "./../AllReducer/R_AppointmentDetails";
const rootReducer = combineReducers({
  R_paymentDetails,
  R_getPatientLogin,
  R_getallspecialties,
  R_GetDoctorsListBySpecialityID,
  R_getDocsTimming,
  R_savePatientAppoint,
  R_getdoctorprofile,
  R_getdoctorfees,
  R_takeappointment,
  R_getpreventivehealthcheckups,
  R_searchText,
  R_getAllCoupons,
  R_AppointmentDetails,
});

const defaultReducer = (state, action) =>
  rootReducer(action?.type === "USER_LOGOUT" ? undefined : state, action);

export default defaultReducer;
