
const initilaState = {}



export const R_savePatientAppoint = (state=initilaState,action) =>{

    switch (action.type) {
        case "SAVE_PATIENT_APPOINT":
            return action.payload
        case "DELETE_PATIENT_APPOINT":
            return {}
    
        default:
            return state
    }

}