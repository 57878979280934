import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "assets/loading/LoadingButton";
import { useNavigate } from "react-router-dom";
import { changePasswordValidation } from "Pages/Patientregister/utils";
import { toast } from "react-hot-toast";
import { cardOrientation } from "assets/util/common/root";
import { callGet } from "common/local";
import API from "util/API/api";
import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [signupCredential, setSignupCredential] = useState({
    newPassword: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...signupCredential },
    resolver: yupResolver(changePasswordValidation),
  });
  const changePassword = async (data) => {
    try {
      setLoading(true);
      const changePasswordResp = await postAPIData({
        ...API.changePassword(data),
      });
      const getRes = changePasswordResp?.data;
      if (getRes?.IsSuccessful) {
        toast.success(getRes?.FriendlyMessage);
        navigate("/");
      } else {
        toast.error(getRes?.FriendlyMessage || "Soemthing went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getFormData = (data) => {
    const authData = callGet("auth");
    let obj = {
      old: data?.oldPassword,
      new: data?.newPassword,
      ...authData,
    };
    changePassword(obj);
  };
  return (
    <div className="change-password">
      <div className="patient_header mt-3 p-5" style={{ ...cardOrientation }}>
        <div>
          <h3 className="text-primary" style={{ fontWeight: "900" }}>
            <strong>Change Password</strong>
          </h3>
        </div>
        <div>
          <div className="form-group mt-2">
            <input
              {...register("oldPassword")}
              type="password"
              name="oldPassword"
              className="form-control p-3"
              aria-describedby="nameHelp"
              placeholder="Old Password"
            />
            {errors?.["oldPassword"] && (
              <p className="errorText">
                {errors?.["oldPassword"]?.message?.toString()}
              </p>
            )}
          </div>
          {/* fullName no */}
          <div className="form-group mt-2">
            <input
              {...register("newPassword")}
              type="password"
              name="newPassword"
              className="form-control p-3"
              aria-describedby="nameHelp"
              placeholder="New Password (Min. 8 chars)"
            />
            {errors?.["newPassword"] && (
              <p className="errorText">
                {errors?.["newPassword"]?.message?.toString()}
              </p>
            )}
          </div>
          {/* mobile no */}
          <div className="form-group mt-2">
            <input
              {...register("confirmPassword")}
              autoComplete="off"
              type="password"
              maxLength="20"
              name="confirmPassword"
              className="form-control p-3 mb-2"
              aria-describedby="mobileHelp"
              placeholder="Confirm Password"
            />
            {errors["confirmPassword"] && (
              <p className="errorText">
                {errors?.["confirmPassword"]?.message?.toString()}
              </p>
            )}
          </div>

          {/* submit */}
          <div style={{ textAlign: "center" }}>
            <LoadingButton
              onClick={handleSubmit(getFormData)}
              loading={loading}
              name={"Save"}
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="centerLoader1">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
