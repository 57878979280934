const initilaState = {
  loading: false,
  data: [],
  patientDetails: {},
};

export const R_getdoctorfees = (state = initilaState, action) => {
  switch (action.type) {
    case "DOC_FEE_CALL":
      return { ...state, loading: true };
    case "DOC_FEE_STOP":
      return { ...state, loading: false };
    case "GET_DOC_FEE":
      return { ...state, data: action?.payload, err: action?.err };
    case "GET_PAT_DETAIL":
      return { ...state, patientDetails: action?.payload, err:'' };
    default:
      return state;
  }
};
