const initilaState = {
  data: {},
};

export const R_AppointmentDetails = (state = initilaState, action) => {
  switch (action.type) {
    case "UPDATE_DATA":
      return { ...state, data: { ...state?.data, ...action?.payload } };
    case "REMOVE_DATA":
      return { ...state, data: {} };
    default:
      return state;
  }
};
