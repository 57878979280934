import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import Sidebar from "./sideBar/Sidebar";
import "./main.css";
function Main() {
  return (
    <React.Fragment>
      <div className="container-fluid mainGrid rootBackground">
        <div className="sidebar-view">
          {" "}
          <Sidebar />
        </div>
        <div className="navbartopbook">
          <Navbar />
          <div className="loader-container">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Main;
