import { Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./main/Main";
import Home from "./Pages/Home/Home";
import SignIn from "./signIn/SignIn";
import SignUp from "./signUp/SignUp";
import MyReport from "./Pages/MyReportMain/MyReport";
import Tracker from "./Pages/IpdTracker/Tracker";
import AppointmentCategories from "./Pages/Appointment/AppointmentCategories/AppointmentCategories";
import DoctorList from "./Pages/Appointment/DoctorList/DoctorList";
import AppointmentTime from "./Pages/Appointment/DoctorList/ApppintmentTime/AppointmentTime";
import PatientPayment from "./Pages/Appointment/DoctorList/ApppintmentTime/PatientName/PatientPayment/PatientPayment";
import PatientRegister from "./Pages/Patientregister/PatientRegister";
import PatientName from "./Pages/Appointment/DoctorList/ApppintmentTime/PatientName/PatientName";
import Payment from "./Pages/Payment/Payment.jsx";
import PayementSummary from "./Pages/Payment/Payementsummary/PayementSummary";
import Sample from "./Pages/HomeSample/Sample";
import QuestionAsk from "./Pages/QuestionAsk/QuestionAsk";
import ViewProfile from "./Pages/Appointment/viewprofile/ViewProfile";
import Private from "./common/Private";
import MyAppointment from "./Pages/MyAppointment/MyAppointment";
import MyAppointmentDetails from "./Pages/MyAppointment/MyAppointmentDetails/MyAppointmentDetails";
import { Toaster, toast } from "react-hot-toast";
import Feedback from "./Pages/Feedback/Feedback";
import PreventiveHealth from "./Pages/PreventiveHealth/PreventiveHealth";
import Immunisation from "./Pages/ImmunisationRecord/Immunisation";
import ForgotPassword from "./Pages/ForgotPasswordPage/ForgotPassword";
import MyProfile from "Pages/MyProfile";
// import ForgotPassword from '.Pages/ForgotPassword/ForgotPassword';
import axios from "axios";
import useLogOut from "Hooks/useLogout";
import PaymentHistory from "Pages/PaymentHistory";
import TermsConditions from "Pages/Terms&Conditions";
import PrivacyPolicy from "Pages/PrivacyPolicy";
import ChangePassword from "main/navbar/ChangePassword";
import ContactUs from "main/navbar/ContactUs";
import RefundPolicy from "Pages/RefundPolicy";
import AllCoupons from "Pages/Appointment/DoctorList/ApppintmentTime/PatientName/PatientPayment/AllCoupons";
import Sucess from "Pages/PaymentRedirects/Sucess";
import PaymentHistoryDetails from "Pages/PaymentHistoryDetails";
import React from "react";
import TeleConsultationPolicy from "Pages/TeleConsultationPolicy";

function App() {
  const [status, callLogOut] = useLogOut();
  axios.interceptors.response.use(
    (res) => {
      if (res?.data?.FriendlyMessage === "INVALID SESSION") {
        toast.error(res?.data?.FriendlyMessage);
        callLogOut("logout");
        return null;
      } else {
        return res;
      }
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  return (
    <React.Fragment>
      <Toaster position="top-right" />
      <Routes>
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/" element={<SignIn />} />
        <Route path="termsConditions" element={<TermsConditions />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
        {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<Private />}>
          <Route path="dashboard" element={<Main />}>
            <Route index element={<Home />} />
            <Route
              path="book-appointment"
              element={<AppointmentCategories />}
            />
            <Route path="redirects/payment" element={<Sucess />} />
            <Route path="specialties" element={<AppointmentCategories />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="refund-policy" element={<RefundPolicy />} />
            <Route path="preventive" element={<PreventiveHealth />} />
            <Route path="book-appointment/doctList" element={<DoctorList />} />
            <Route
              path="book-appointment/doctList/view-profile"
              element={<ViewProfile />}
            />
            <Route
              path="book-appointment/doctList/appointDoc/view-profile"
              element={<ViewProfile />}
            />
            <Route
              path="book-appointment/doctList/appointDoc"
              element={<AppointmentTime />}
            />
            <Route
              path="book-appointment/doctList/appointDoc/patientName"
              element={<PatientName />}
            />
            <Route
              path="book-appointment/doctList/appointDoc/patientName/payment"
              element={<PatientPayment />}
            />
            <Route
              path="book-appointment/doctList/appointDoc/patientName/payment/all-coupons"
              element={<AllCoupons />}
            />
            <Route path="specialties/doctList" element={<DoctorList />} />
            <Route
              path="specialties/doctList/view-profile"
              element={<ViewProfile />}
            />
            <Route
              path="specialties/doctList/appointDoc/view-profile"
              element={<ViewProfile />}
            />
            <Route
              path="specialties/doctList/appointDoc"
              element={<AppointmentTime />}
            />
            <Route
              path="specialties/doctList/appointDoc/patientName"
              element={<PatientName />}
            />
            <Route
              path="specialties/doctList/appointDoc/patientName/payment"
              element={<PatientPayment />}
            />
            <Route path="Payment" element={<Payment />} />
            <Route path="patient-registration" element={<PatientRegister />} />
            <Route
              path="Payment/PayementSummary"
              element={<PayementSummary />}
            />
            <Route path="MyProfile" element={<MyProfile />} />
            <Route
              path="Sample-collection"
              element={<Sample page={"HomeSample"} />}
            />
            <Route path="Ambulance" element={<Sample page={"Ambulance"} />} />
            <Route path="QuestionAsk" element={<QuestionAsk />} />
            <Route path="MyReport" element={<MyReport />} />
            <Route path="IpdTracker" element={<Tracker />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="Immunisation" element={<Immunisation />} />
            <Route path="IpdTracker" element={<Tracker />} />
            <Route path="myAppointment" element={<MyAppointment />} />
            <Route path="paymentHistory" element={<PaymentHistory />} />
            <Route
              path="paymentHistoryDetails"
              element={<PaymentHistoryDetails />}
            />
            <Route path="termsConditions" element={<TermsConditions />} />
            <Route path="privacyPolicy" element={<PrivacyPolicy />} />
            <Route
              path="tele-consultation-policy"
              element={<TeleConsultationPolicy />}
            />

            <Route
              path="myAppointment/myappntmtDetils"
              element={<MyAppointmentDetails />}
            />
            <Route path="FeedBack" element={<Feedback />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Route>
      </Routes>
    </React.Fragment>
  );
}

export default App;
