import React from "react";
import "./rootselect.css";

function RootSelect(props) {
  const {
    placeholder,
    options,
    bootstrap,
    selectStyle,
    divStyle,
    onChange,
    name,
    register,
    error,
    setSelectedOption,
  } = props;

  // const optionsCss = {
  //     color:"orange"
  // }

  const { divCss, selectCss } = bootstrap;
  return (
    <React.Fragment>
      <div className={divCss} style={divStyle}>
        <select
          id="rootField"
          {...register(name, {
            onChange: (e) => {
              setSelectedOption && setSelectedOption(e?.target?.value);
            },
          })}
          required
          name={name}
          className={selectCss}
          // onChange={(e) => onChange(e)}
          style={{ ...selectStyle }}
          aria-label="Default select example"
          placeholder={placeholder}
        >
          <option value={""} disabled selected>{placeholder}</option>
          {options?.length ? (
            options.map((e, i) => <option key={i} value={e?.value}>{e?.name}</option>)
          ) : (
            <option value="">Loading...</option>
          )}
        </select>
        {error?.[name ?? ""] && (
          <p className="errorText">
            {error?.[name ?? ""]?.message?.toString()}
          </p>
        )}
      </div>
    </React.Fragment>
  );
}

export default RootSelect;
