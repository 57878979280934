import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import SearchTable from "./searchTable";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Table = ({ rowsData, Columns, currPage, setCurrPage = () => {} }) => {
  const tableInstance = useTable(
    {
      columns: Columns,
      data: rowsData,
      initialState: { pageIndex: currPage - 1 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps, // table props from react-table
    headerGroups, // headerGroups, if your table has groupings
    getTableBodyProps, // table body props from react-table
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    footerGroups,
    state,
    setGlobalFilter,
    page, // use, page or rows
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = tableInstance;
  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <div>
      {/* <h4 className="report-header fw-bold" >Report List</h4> */}
      <span className="table-filter">
        <div className="table-filterleft">
          <SearchTable val={globalFilter} setValue={setGlobalFilter} />
        </div>
        <div className="table-filterright">
          <select
            className="table-page-select"
            value={pageSize}
            onChange={(e) => {
              setCurrPage(() => 1);
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50, "All"].map((pageSize, i) => (
              <option
                key={i}
                value={pageSize === "All" ? rowsData?.length : pageSize}
              >
                {pageSize}
              </option>
            ))}
          </select>{" "}
          per page
        </div>
      </span>
      <div className="table-responsive">
        <table {...getTableProps()} className="table table-hover mb-0 mt-3">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, j) => (
                  <th
                    key={i + j}
                    {...column.getHeaderProps(column?.getSortByToggleProps())}
                  >
                    {column?.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td key={i + j} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-page-options">
        <div className="table-page-optionsleft">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions?.length}
            </strong>{" "}
          </span>
        </div>
        <div className="table-page-optionsright">
          <Stack spacing={2}>
            <Pagination
              count={pageOptions?.length}
              onChange={(e, page) => {
                gotoPage(page - 1);
                setCurrPage(() => page);
              }}
              page={currPage}
              color="primary"
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Table;
