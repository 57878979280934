import axios from "axios";
import { SERVER } from "server/server";
const baseUrl = SERVER;
export const getAPIData = (data) => {
  return axios.get(baseUrl + `${data?.url}`, {
    params: {
      ...data?.data,
    },
  });
};

export const postAPIData = (data) => {
  return axios.post(baseUrl + `/${data?.url}`, {
    ...data?.body,
  });
};

export const postFormAPIData = (data) => {
  return axios.post(baseUrl + `/${data?.url}`, data.body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
