import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  font,
  cardOrientation,
  linkStyle,
} from "../../assets/util/common/root";
import { callGet } from "../../common/local";
import API from "../../util/API/api";
import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
import TrackerLine from "./Tracker_line";

export default function Tracker() {
  const navigate = useNavigate();
  const authData = { ...callGet("auth") };
  const mainDiv = { ...cardOrientation, minHeight: "50%" };
  const [UHIDList, setUHIDList] = useState([]);
  const [disChargeData, setDischargeData] = useState({});
  const [errMsg, setErrorMsg] = useState({
    uhid: "",
    discharge: "",
  });
  const [loading, setLoading] = useState(false);
  const [hospitalType, setHospitalType] = useState([]);
  const [body, setBody] = useState({
    FacilityId: "",
    UHID: "",
  });

  const getAdmittedPatients = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({
        ...API.getAdmittedPatients(data),
      });
      if (!resp?.data?.IsSuccessful || !resp?.data?.Data?.length) {
        throw resp?.data;
      }
      setUHIDList(resp?.data?.Data);
    } catch (err) {
      console.error(err);
      setErrorMsg({
        uhid: "No Admitted Patients Found",
        discharge: "",
      });
    } finally {
      setLoading(false);
    }
  };
  const getIPDStatus = async (data) => {
    try {
      setLoading(true);
      const ipdResp = await postAPIData({ ...API.getipdstatus(data) });
      const getIpdstatus = ipdResp?.data;
      if (!getIpdstatus.IsSuccessful) {
        console.error(getIpdstatus?.FriendlyMessage);
        setErrorMsg({
          uhid: "",
          discharge: getIpdstatus?.FriendlyMessage,
        });
      } else {
        setErrorMsg({
          uhid: "",
          discharge: "",
        });
        setDischargeData(getIpdstatus?.Data?.[0]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (body?.FacilityId && body?.UHID) {
      const payload = { ...callGet("auth"), ...body };
      getIPDStatus(payload);
    }
  }, [body?.FacilityId, body?.UHID]);

  const getHospitalData = async (data) => {
    try {
      setLoading(true);
      const uhidByMobile = await postAPIData({
        ...API.getuhidlistbymobile(data),
      });
      const getData = uhidByMobile?.data;
      if (getData?.IsSuccessful) {
        setUHIDList(getData?.Data);
      } else {
        console.error(getData?.FriendlyMessage);
      }
      const activeHospitalResp = await postAPIData({
        ...API.getactivehospitals(data),
      });
      const getActiveHospitals = activeHospitalResp?.data;
      if (getActiveHospitals?.IsSuccessful) {
        setHospitalType(getActiveHospitals?.Data[0]?.Hospitals);
      } else {
        console.error(getActiveHospitals?.FriendlyMessage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
    getHospitalData(authData);
  }, []);
  // console.log(disChargeData, "------->");
  return (
    <div className="container-fluid p-0 mt-2" style={mainDiv}>
      {loading && (
        <div className="centerLoader1">
          <Loader />
        </div>
      )}
      <div className="w-100 p-5">
        <h4 className="heading fw-bold" style={font}>
          IPD DISCHARGE TRACKER
        </h4>
        <div className="row">
          <div className="col-md-4 p-2">
            <select
              disabled={loading}
              onChange={(e) => {
                setDischargeData({});
                setUHIDList([]);
                setBody({ FacilityId: "", UHID: "" });
                getAdmittedPatients({
                  ...authData,
                  FacilityId: e.target.value,
                });
                setErrorMsg({
                  uhid: "",
                  discharge: "",
                });
                setBody({ UHID: "", [e.target.name]: e.target.value });
              }}
              name="FacilityId"
              id="rootField"
              value={body?.FacilityId || ""}
              className="form-control p-3 rootselect"
              aria-label="Default select example"
              placeholder="Select Hospital"
              required
            >
              <option value="" disabled selected>
                Select Hospital
              </option>
              {hospitalType?.length ? (
                hospitalType?.map((e, i) => (
                  <option key={i} value={e?.FacilityId}>
                    {e?.FacilityName}
                  </option>
                ))
              ) : (
                <option value={null}>
                  {loading ? "loading..." : "No Data Found"}
                </option>
              )}
            </select>
          </div>
          <div className="col-md-4 p-2">
            <select
              disabled={loading || !body?.FacilityId}
              onChange={(e) => {
                setDischargeData({});
                setErrorMsg({
                  ...errMsg,
                  discharge: "",
                });
                setBody({ ...body, [e.target.name]: e.target.value });
              }}
              value={body?.UHID || ""}
              name="UHID"
              id="rootField"
              className="form-control p-3 rootselect"
              aria-label="Default select example"
              required
            >
              <option value="">Select UHID</option>
              {UHIDList?.length ? (
                UHIDList?.map((data, index) => (
                  <option value={data?.UHID} key={"UHIDList" + index}>
                    {data?.UHID} - {data?.Name}
                  </option>
                ))
              ) : (
                <option value={null}>
                  {loading ? "loading..." : "No Data Found"}
                </option>
              )}
            </select>
          </div>
          {errMsg?.uhid && <p className="errorText">{"*" + errMsg?.uhid}</p>}
          {errMsg?.discharge && (
            <p className="errorText">{"*" + errMsg?.discharge}</p>
          )}
          {!Object.keys(disChargeData || {})?.length && (
            <p className="mt-3">
              Please select <b>UHID</b>. If you don't have an <b>UHID</b>{" "}
              <u
                style={linkStyle}
                onClick={() => navigate("/dashboard/patient-registration")}
              >
                please register
              </u>{" "}
            </p>
          )}
          {Object.keys(disChargeData || {})?.length > 0 && (
            <TrackerLine data={disChargeData} />
          )}
        </div>
      </div>
    </div>
  );
}
