const initilaState = {
  loading: false,
  data: [],
};

export const R_GetDoctorsListBySpecialityID = (
  state = initilaState,
  action
) => {
  switch (action.type) {
    case "DLIST_BY_ID_CALL":
      return { ...state, loading: true };
    case "DLIST_BY_ID_STOP":
      return { ...state, loading: false };

    case "GET_DLIST_BY_ID":
      return { ...state, data: action.payload };

    default:
      return state;
  }
};
