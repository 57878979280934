const initilaState = [];

export const R_paymentDetails = (state = initilaState, action) => {
  switch (action?.type) {
    case "Payment_Details":
      return { ...action.payload };

    default:
      return state;
  }
};
