const initilaState = {
  loading: false,
  data: {},
  hash: "",
};

export const R_takeappointment = (state = initilaState, action) => {
  switch (action.type) {
    case "TAKE_APPOINT_CALL":
      return { ...state, loading: true };

    case "TAKE_APPOINT_STOP":
      return { ...state, loading: false };

    case "GET_TAKE_APPOINT":
      return { ...state, loading: false, data: action.payload };
    case "SET_HASH":
      return { ...state, loading: false, hash: action.payload };
    case "CLEAR_TAKE_APPOINT":
      return { ...state, loading: false, data: {}, hash: "" };

    default:
      return state;
  }
};
