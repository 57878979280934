import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "./Notification.css";
import { postAPIData } from "Hooks/Services";
import API from "util/API/api";
import { toast } from "react-hot-toast";
import { callGet } from "common/local";
export default function NotificationIcon({
  text,
  badgeCount,
  setBadgeCount = () => {},
}) {
  const getNotifications = async (data) => {
    try {
      // setLoading(true);
      var notificationResp = await postAPIData(API.getNotifications(data));
      notificationResp = notificationResp?.data;
      if (notificationResp?.IsSuccessful) {
        const respData = notificationResp?.Data?.[0];
        setBadgeCount(respData?.BadgeCount || 0);
      } else {
        toast.error(
          notificationResp?.FriendlyMessage || "Something went wrong"
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    if (badgeCount) return;
    const authData = callGet("auth");
    getNotifications({ ...authData, FacilityId: authData?.hospitalId });
  }, []);
  return (
    <div className="notificationContainer">
      {badgeCount > 0 && (
        <p className="notificationCount bg-red">{badgeCount}</p>
      )}
      <Icon style={{ fontSize: "25px", color: "rgb(120 118 118)", marginTop:"5px" }} icon="ion:notifications-outline" />
      {text === "notification" && (
        <div className="downArrow">
          <Icon
            style={{ fontSize: "22px", height: "30px" }}
            icon="ep:arrow-down-bold"
            color="gray"
          />
          <Icon
            style={{ fontSize: "18px", height: "10px" }}
            icon="ep:arrow-down-bold"
            color="gray"
          />
        </div>
      )}
    </div>
  );
}
