import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../Reducer/Root/index";
import {StateLoader} from '../storage/storage'
const stateLoader = new StateLoader();
let store = createStore(rootReducer, stateLoader.loadState(),applyMiddleware(thunk));

store.subscribe(() => {
    stateLoader.saveState(store.getState());
});

export default store