
function callSet (local) {
    localStorage.setItem(`${local?.name}`,JSON.stringify(local?.data))
}
function callGet (name) {
    return JSON.parse(localStorage.getItem(`${name}`))
}
function delLocal (name) {
    localStorage.removeItem(`${name}`)
}

export {callSet,callGet,delLocal}