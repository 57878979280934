import { postAPIData } from "Hooks/Services";
import RootSelect from "assets/util/Root/RootSelect";
import { cardOrientation } from "assets/util/common/root";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import API from "util/API/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import back from "assets/mainIcon/icons/Vector.png";
import Loader from "Pages/Loader";

const schema = yup.object().shape({
  reason: yup.string().required("Reason is required"),
});
const CancelAppointment = ({
  loading,
  setLoading = () => {},
  userData,
  data,
  cancel,
  setCancel = () => {},
}) => {
  const [reasons, setReasons] = useState([]);
  const icon = { transform: "rotate(90deg)" };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      reason: "",
    },
    resolver: yupResolver(schema),
  });
  const cancelReasons = async () => {
    try {
      setLoading(true);
      const reasonResp = await postAPIData({
        ...API.appointmentCancelReason({ ...userData }),
      });
      if (!reasonResp?.data?.IsSuccessful) {
        toast.error(
          reasonResp?.data?.FriendlyMessage || "Something went wrong"
        );
        return;
      }
      setReasons(
        reasonResp?.data?.Data?.map((e) => {
          return {
            name: e?.Text,
            value: e?.Value,
          };
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const cancelAppointmentReq = async (cancelData) => {
    try {
      setLoading(true);
      const resp = await postAPIData({
        ...API.appointmentCancel({
          AppointmentId: cancelData?.AppointmentId,
          UHID: cancelData?.UHID,
          ReasonId: cancelData?.reason,
          Remark: cancelData?.Remark,
          InvoiceId: cancelData?.InvoiceId,
          ...userData,
        }),
      });
      if (resp?.data?.IsSuccessful) {
        toast.success(resp?.data?.FriendlyMessage || "Appointment cancelled");
        setCancel(false);
      } else {
        toast.error(resp?.data?.FriendlyMessage || "Something went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    cancelReasons();
  }, []);
  const getReason = (reasonData) => {
    reasonData = {
      ...reasonData,
      Remark: reasons?.find((e) => e?.value == reasonData?.reason)?.name,
    };
    cancelAppointmentReq({
      ...data,
      ...reasonData,
    });
  };
  return (
    <div className="--header container-fluid p-4 mt-2 " style={cardOrientation}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {" "}
        <h4 style={{ width: "600px" }}>Cancel Appointment</h4>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <button className="btn-text-center" onClick={() => setCancel(false)}>
            <img style={icon} draggable="false" src={back} alt="go back" />
            <p className="back-btn-text">Go Back </p>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 p-2">
          <div>Select a reason to continue</div>
          <RootSelect
            bootstrap={{
              divCss: "col-md-4 p-2",
              selectCss: "form-control p-2 rootselect",
            }}
            placeholder={"Select a reason*"}
            options={reasons}
            name={"reason"}
            register={register}
            error={errors}
          />
        </div>
      </div>
      <div>
        {" "}
        <button
          onClick={handleSubmit(getReason)}
          className="reschedule-text-center"
        >
          Cancel Appointment
        </button>
      </div>
      {loading && (
        <div className="centerLoader1">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default CancelAppointment;
