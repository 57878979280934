export const action_type = {
  1: {
    call: "DLIST_BY_ID_CALL",
    stop: "DLIST_BY_ID_STOP",
    data: "GET_DLIST_BY_ID",
  },
  2: {
    call: "DTIME_BY_ID_CALL",
    stop: "DTIME_BY_ID_STOP",
    data: "GET_DTIME_BY_ID",
  },
  3: {
    call: "GET_DOC_PRO_CALL",
    stop: "GET_DOC_PRO_STOP",
    data: "GET_DOC_PRO",
  },
  4: { call: "DOC_FEE_CALL", stop: "DOC_FEE_STOP", data: "GET_DOC_FEE" },
  5: {
    call: "TAKE_APPOINT_CALL",
    stop: "TAKE_APPOINT_STOP",
    data: "GET_TAKE_APPOINT",
  },
  6: {
    call: "PREVENTIVE_CALL",
    stop: "PREVENTIVE_STOP",
    data: "GET_PREVENTIVE",
  },
};
