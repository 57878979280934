import * as yup from "yup";
import moment from "moment";
export const PatientRagistrationFormFields = {
  TitleId: "",
  FirstName: "",
  LastName: "",
  DateofBirth: "",
  GenderId: "",
  MaritalStatusId: "",
  LocalAddress: "",
  CountryId: "",
  StateId: "",
  CityId: "",
  AreaId: "",
  Zip: "",
  FacilityId: "",
  NationalityId: "",
  MobileNumber: "",
  EmailId: "",
};

export const PatientRegistrationFormValiation = yup
  .object()
  .shape({
    EmailId: yup
      .string()
      .required("Email is Required")
      .email("Please enter a valid email")
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        "Please enter a valid email"
      ),
    FirstName: yup
      .string()
      .required("Name is required")
      .min(3, "Minimum 3 characters required")
      .test("spaces", "Name is required", (val) => !!val.trim()),
    LocalAddress: yup
      .string()
      .min(4, "Minimum 3 characters required")
      .required("Address is required")
      .test("spaces", "Address is required", (val) => !!val.trim()),
    DateofBirth: yup.string().required("Date of Birth is required"),
    GenderId: yup.string().required("Gender is required"),
    MaritalStatusId: yup.string().required("Marital Status is required"),
    CountryId: yup.string().required("Country is required"),
    Zip: yup.string(),
    NationalityId: yup.string().required("Nationality is required"),
    MobileNumber: yup
      .string()
      .required("Number is required")
      .min(10, "Phone number is not valid")
      .matches(/(0|91)?[6-9][0-9]{9}/, "Phone number is not valid")
      .test("numberCheck", "Phone number is not valid", (val) =>
        val?.toString()?.startsWith(0)
          ? val?.length == 11
          : val?.toString()?.startsWith(91)
          ? val?.length == 12
          : val?.length == 10
      ),
    TitleId: yup.string().required("Title is required"),
  })
  .when((values, schema) => {
    if (values?.CountryId) {
      return schema?.shape({
        StateId: yup.string().required("State is required"),
      });
    }
  })
  .when((values, schema) => {
    if (values?.StateId) {
      return schema?.shape({
        CityId: yup.string().required("City is required"),
      });
    }
  });

export const changePasswordValidation = yup.object({
  oldPassword: yup
    .string()
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
      "Please enter your old password."
    )
    .required("Old password is required"),
  newPassword: yup
    .string()
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
      "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters."
    )
    .required("Please enter your new password."),
  confirmPassword: yup
    .string()
    .required("Please retype your password.")
    .oneOf([yup.ref("newPassword")], "Password Not Matched"),
});

export const getIndiaId = (data, text) => {
  for (let key of data) {
    if (key?.Text === text) {
      return key?.Value;
    }
  }
  return "";
};
export const isTimeDifference24Hours = (dateString) => {
  if (!dateString) return false;
  const date1 = new Date(dateString);
  const date2 = new Date(+new Date().getTime() + 24 * 60 * 60 * 1000);
  return date1 > date2;
};
export const isTimeDifference15Mins = (dateString) => {
  if (!dateString) return false;

  const date1 = new Date(dateString)?.getTime();

  const date2 = +new Date()?.getTime() + +15 * 60 * 1000;
  return date1 > date2;
};

export const firstCap = (text) => {
  if (!text) return "";
  return text?.substr(0, 1)?.toLocaleUpperCase() + text?.substr(1);
};
