
const initilaState = {}



export const R_getPatientLogin = (state=initilaState,action) =>{

    switch (action.type) {
        case "GET_PATIENT_LOGIN":
            return {...state,...action.payload}
    
        default:
            return state
    }

}