import { useEffect, useState } from "react";
import { SERVER } from "../server/server";
import { toast } from "react-hot-toast";
import axios from "axios";

function usePost() {
  const [getData, setData] = useState("");
  const [api, setApi] = useState("");
  const [loading, setLoading] = useState(false);
  async function callApi() {
    try {
      setLoading(true);
      const response = await axios.post(`${SERVER}/${api?.url}`, api?.body);
      setData(response.data);
    } catch (err) {
      console.error(err);
      toast.error(err.FriendlyMessage || "Something went wrong !!");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (api) {
      callApi();
    }
  }, [api]);

  return [loading, getData ? getData : null, setApi];
}

export default usePost;
