import React, { useEffect, useState } from "react";
import "./sidebar.css";

import mainLogo from "../../assets/image/mahospital.png";
import { sidebarData } from "../../assets/sideBarData/sidebarData";
import { useNavigate, useLocation } from "react-router-dom";
import actions from "Action/action";
import { useDispatch } from "react-redux";
import { callGet } from "common/local";
import { POLICY_BASE_URL } from "server/server";
function Sidebar({ style, showSidebar, setShowSidebar = () => {} }) {
  const dispatch = useDispatch();
  const userData = callGet("auth");
  const location = useLocation();
  const [activeTab, onSetTabHandler] = useState(
    location?.pathname?.split("dashboard")?.[1]
  );
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
    onSetTabHandler(location?.pathname?.split("dashboard")?.[1]);
  }, [location.pathname]);
  const checkActive = (e) => {
    if (
      (location.pathname === "/dashboard" && e?.path === "/dashboard") ||
      activeTab.includes(e?.value) ||
      activeTab.includes(e?.path)
    ) {
      return true;
    }
    return false;
  };
  return (
    <div>
      <nav className="sidebar shadow-lg card-body" style={style}>
        <div className="top_bar logo text-center">
          <img
            onClick={() => {
              navigate("/dashboard");
            }}
            src={mainLogo}
            draggable={"false"}
            style={{
              width: 108,
              objectFit: "cover",
              height: "auto",
              margin: "28px 0px",
              cursor: "pointer",
            }}
            alt="ma-hospital"
          ></img>
        </div>
        <ul className="list-unstyled components">
          {/* Home nav item */}
          {sidebarData?.map((e, index) => (
            <li
              className="nav-item hr"
              onClick={() => {
                navigate(e?.path);
                onSetTabHandler(e?.value);
                setShowSidebar(false);
              }}
              key={"sideBar" + index}
            >
              <span
                className={
                  checkActive(e)
                    ? "d-flex align-items-center nav-link active"
                    : "d-flex align-items-center nav-link "
                }
              >
                {/* <FaHome className="me-2" size={14} />  */}
                {e.title}
              </span>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
