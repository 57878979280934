import React, { useLayoutEffect } from "react";
import MainIcon from "../../assets/mainIcon/MainIcon";
import { callGet } from "common/local";

function Home() {
  const userData = callGet("auth")?.hospitalName?.split(",");
  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <MainIcon top={15} left={20} width={350} />
      </div>
      <div style={{ paddingTop: "30%", paddingLeft: "22%" }}>
        <h4>
          <b style={{ color: "#1A2352" }}>WELCOME TO</b>
        </h4>
        <h1>
          <b style={{ color: "#1A2352", fontSize: "50px" }}>
            {userData?.[0] || "Marengo Asia Hospitals"}
          </b>
        </h1>
        <p>
          <b style={{ color: "#1A2352" }}>{userData?.[1]?.trim() === 'Faridabad' && "Sec-16," }{userData?.[1]|| "FARIDABAD"}</b>
        </p>
      </div>
    </React.Fragment>
  );
}

export default Home;
